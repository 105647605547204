<template>
  <section class="hero hero-mailchimp bg-primary-alt new-hero" id="hero">
    <div class="p-5 white--text">
      <h1 class="white--text">Kebijakan Privasi</h1>
      <i class="white--text">Terakhir di Update: 02 September 2024</i>
      <div class="row items-push mt-10">
        <div class="col-md-4 bg-white">
          <div class="p-5">
            <h4 class="text-primary">Daftar Isi</h4>
            <ul class="mt-5 point-lists black--text list-unstyled">
              <li><a href="#pendahuluan">1. Pendahuluan</a></li>
              <li><a href="#data">2. Data yang Kami Kumpulkan</a></li>
              <li><a href="#payment">3. Data Pembayaran</a></li>
              <li><a href="#use">4. Bagaimana Kami Menggunakan Data Anda</a></li>
              <li><a href="#share">5. Bagaimana Kami Membagikan Data Anda</a></li>
              <li><a href="#security">6. Keamanan</a></li>
              <li><a href="#time">7. Jangka Waktu Penyimpanan</a></li>
              <li><a href="#agreement">8. Persetujuan</a></li>
              <li><a href="#contact">9. Hubungi Kami</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-8 bg-white">
          <div class="p-5 black--text">
            <div class="paragraph">
              <div>
                Dalam Kebijakan Privasi ini, Kami merujuk kepada <b>“Anda“</b>. <b>“Anda”</b> dapat berarti pengunjung
                situs Kami, atau pengguna dari salah satu atau lebih Layanan Kami. Kami akan melakukan yang terbaik untuk
                mengklarifikasi kepada siapa Kami merujuk dalam berbagai poin dalam Kebijakan Privasi ini. Kebijakan ini
                tidak berlaku untuk situs, produk, atau layanan pihak ketiga bahkan dalam hal mereka tertaut kepada
                Layanan Kami, dan Anda harus memperhatikan praktik privasi pihak ketiga tersebut dengan seksama. Jika Anda
                tidak setuju dengan praktik yang dijelaskan dalam Kebijakan Privasi ini, Anda harus segera menghentikan
                penggunaan Layanan Kami dan segera menghapus cookie dari perangkat komputer Anda setelah meninggalkan
                situs Kami.
              </div>

              <div class="mt-5">
                <h4 id="pendahuluan">1. Pendahuluan</h4>
                <div>
                  Data yang Kami kumpulkan tergantung pada bagaimana Anda menggunakan layanan Kami. Kami menerima Data
                  secara langsung, seperti ketika Anda membuat akun. Selain itu, Kami menerima Data dengan merekam
                  interaksi dengan Layanan Kami dengan, sebagai contoh, menggunakan teknologi seperti cookie dan web
                  beacon. Pengumpulan dan penggunaan data dari berbagai sumber bersifat esensial untuk pengembangan
                  Layanan Kami dan membantu mengamankan Layanan tersebut. Data bersifat penting dalam membantu Kami
                  meningkatkan pelayanan untuk Anda, dan mengurangi resiko tindak pidana.
                </div>
              </div>

              <div class="mt-5">
                <h4 id="data">2. Data yang Kami Kumpulkan</h4>
                <div>
                  <ol type="a">
                    <li>
                      <b>Data pribadi.</b> Data yang mengidentifikasi, atau dapat secara wajar digunakan untuk
                      mengidentifikasi Anda sebagai seorang individual. Kami menggunakan beberapa cara untuk mengumpulkan
                      Data Pribadi. Sebagai contoh, Kami mengumpulkan Data Pribadi Anda ketika Anda membuat akun.
                      <br /><br />
                      Data Pribadi yang dapat Kami kumpulkan meliputi:
                      <ul>
                        <li>Detail kontak, seperti nama, alamat surat menyurat, nomor telepon, alamat email;</li>
                        <li>Data Pribadi lainnya, seperti tanggal lahir, identitas lain yang dikeluarkan secara resmi oleh
                          pemerintah.</li>
                      </ul>
                    </li>
                    <br /><br />
                    <li>
                      <b>Data lainnya.</b> Data yang bukan merupakan Pribadi Data. Kami menggunakan beberapa cara untuk
                      mengumpulkan Data Pribadi. Kami mengumpulkan Data Lain melalui berbagai sumber. Salah satu sumber
                      Data Lain Kami adalah cookie dan teknologi lainnya yang merekam Data terkait penggunaan situs Kami.
                      <br /><br />
                      Data Pribadi yang dapat Kami kumpulkan meliputi:
                      <ul>
                        <li>Data browser dan perangkat, seperti alamat IP, jenis perangkat, sistem operasi dan jenis
                          internet browser, resolusi layar, nama serta versi dari sistem operasi, pabrik dan model dari
                          perangkat, bahasa, plug-ins, add-ons, serta versi dari Layanan yang Anda gunakan;</li>
                        <li>Data transaksi, seperti pembelian, jumlah pembelian, tanggal pembelian, dan metode pembayaran;
                          dan</li>
                        <li><i>Cookie</i> dan data teknologi pelacakan, seperti waktu yang dihabiskan pada Layanan,
                          halaman yang dikunjungi, preferensi bahasa, dan data lalu lintas anonim lainnya;</li>
                      </ul>
                    </li>
                  </ol>
                  <div class="my-5">(Data Pribadi dan Data Lain secara bersama-sama disebut sebagai <b>“Data”</b>)</div>
                </div>
              </div>

              <div class="mt-5">
                <h4 id="payment">3. Data Pembayaran</h4>
                <div>
                  Kami mungkin mengumpulkan data lain yang diperlukan untuk memproses pembayaran Anda jika Anda memilih untuk
                  melakukan pembelian, seperti nomor alat pembayaran Anda, dan kode keamanan yang terkait dengan alat pembayaran Anda. Semua data pembayaran ditangani dan disimpan oleh Xendit. Anda dapat menemukan tautan kebijakan privasi mereka di sini:
                  <a href="https://www.xendit.co/id/privacy-policy/" target="_blank">https://www.xendit.co/id/privacy-policy/.</a>
                </div>
              </div>

              <div class="mt-5">
                <h4 id="use">4. Bagaimana Kami Menggunakan Data Anda</h4>
                <div>
                  Kami menggunakan Data Pribadi untuk menyediakan Layanan; mendeteksi dan mencegah penipuan, memitigasi kerugian finansial atau bahaya lain terhadap Pengguna, Pelanggan, dan pihak QRCBN sendiri, dan juga mempromosikan, menganalisa dan meningkatkan produk, sistem, dan layanan Kami.
                </div>
              </div>

              <div class="mt-5">
                <h4 id="share">5. Bagaimana Kami Membagikan Data Anda</h4>
                <div>
                  Kami tidak menjual maupun menyewakan Data Anda kepada kepada siapapun, kecuali :
                  <ul>
                    <li>
                      Pihak Ketiga Yang Memiliki Kewenangan. Kami membagikan Data dengan pihak yang secara langsung diberikan kewenangan oleh Pengguna untuk menerima Data, seperti ketika Pengguna melakukan pembelian, makan akan otomatis diarahkan pada pihak ketiga, dalam hal ini "Xendt". Penggunaan Data oleh pihak ketiga yang memiliki kewenangan tunduk pada kebijakan privasi pihak ketiga tersebut;
                    </li>
                    <li>
                      Pihak Ketiga. Kami akan membagikan Data dengan pihak ketiga (apabila perlu diketahui) dalam hal reorganisasi, penggabungan, penjualan, usaha patungan, pengalihan, transfer atau pelepasan lain atau semua atau segala bagian dari usaha, aset atau saham Kami (termasuk sehubungan dengan proses kepailitan atau serupa); dan
                    </li>
                    <li>
                      Untuk Tujuan Keamanan, Hukum, dan Penegakan Hukum. Kami menggunakan dan membagikan Data yang menurut Kami diperlukan: (i) berdasarkan hukum yang berlaku; (ii) untuk melaksanakan syarat dan ketentuan Kami; (iii) untuk melindungi hak, privasi, keamanan atau properti Kami dan/atau afiliasi Kami, Anda, atau lainnya; dan (iv) untuk merespon permintaan dari pengadilan, pejabat penegak hukum, pejabat pembuat peraturan dan segala otoritas publik dan pemerintahan lainnya, yang dapat mencakup otoritas di luar negara tempat Anda tinggal.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="mt-5">
                <h4 id="security">6. Keamanan</h4>
                <div>
                  Kami menyimpan semua data dalam Database yang terenkripsi. Hak akses hanya terbatas untuk personil yang berwenang. Sayangnya, tidak ada sistem transmisi atau penyimpanan yang dapat dijamin 100% aman. Apabila Anda memiliki alasan untuk menduga bahwa interaksi Anda dengan Kami tidak lagi aman (sebagai contoh, jika Anda merasa bahwa keamanan akun Anda terancam), mohon segera berhenti menggunakan Layanan Kami dan menghubungi Kami.
                </div>
              </div>

              <div class="mt-5">
                <h4 id="time">7. Jangka Waktu Penyimpanan</h4>
                <div>
                  Kami akan menyimpan Data Pribadi selama jangka waktu yang diperlukan untuk memenuhi Tujuan Pemrosesan Data kecuali suatu jangka waktu lebih lama disyaratkan atau diperbolehkan berdasarkan peraturan perundang-undangan (selama 10 (sepuluh) tahun berdasarkan peraturan perundang-undangan yang berlaku), atau saat Anda mengirimkan permohonan tertulis kepada Kami untuk menghapus dan memusnahkan Data Anda. Permohonan tertulis Anda merupakan pengakuan Anda bahwa Anda tidak akan meminta pertanggungjawaban Kami apabila kami tidak dapat / tidak bisa memenuhi permintaan Layanan Anda. Dalam keadaan apapun, Kami menjamin bahwa Data yang kami musnahkan akan menyebabkan Data tersebut tidak dapat lagi digunakan untuk tujuan apapun.
                </div>
              </div>

              <div class="mt-5">
                <h4 id="agreement">8. Persetujuan</h4>
                <div>
                  Dengan menerima Kebijakan Privasi, Anda mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan Anda menerima semua ketentuannya. Secara khusus, Anda paham dan setuju bahwa Kami mengumpulkan, menggunakan, membagikan, menyimpan, serta memproses Data Pribadi Anda sesuai dengan Kebijakan Privasi ini.
                </div>
              </div>

              <div class="mt-5">
                <h4 id="contact">9. Hubungi Kami</h4>
                <div>
                  Jika Anda memiliki pertanyaan terkait kebijakan privasi, atau memiliki saran, silahkan hubungi kami melalui <a href="mailto:help@qrcbn.com" target="_blank">help@qrcbn.com</a>.
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

</section></template>

<script>
export default {


}
</script>

<style scoped>.paragraph {
  font-size: 18px;
  text-align: justify;
}

.point-lists {
  font-size: 18px;
}</style>